import React from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import './style.scss';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import { isAndroid } from 'mobile-device-detect';
import IconButton from '@material-ui/core/IconButton';
import safeAreaInsets from 'safe-area-insets';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Icon from '../../components/Icon';
import { config } from '../../settings';
import packageJson from '../../../package.json';

const { SplashScreen } = window.Capacitor.Plugins;

// https://reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null, comment: null, submitting: false };
  }

  componentDidCatch(error, errorInfo) {
    try {
      Sentry.setExtra('error_boundary', true);
      Sentry.captureException(error);
      LogRocket.captureException(error);
      if (window.Capacitor.isNative && SplashScreen) {
        (async () => {
          await SplashScreen.hide();
        })();
      }
    } catch (err) {
      // FIXME Do anything?
    }
    this.setState({ hasError: true, error, errorInfo });
  }

  goBack = () => {
    window.history.back();
    setTimeout(() => {
      this.setState({ hasError: false, error: null, errorInfo: null });
    }, 100);
  }

  backArrow = () => {
    if (isAndroid) {
      return (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="Go Back"
          onClick={this.props.onClose || this.goBack}
        >
          <Icon
            name="arrow-left"
            size={22}
            style={{ height: 22, width: 22 }}
          />
        </IconButton>
      );
    }
    return (
      <IconButton
        edge="start"
        color="inherit"
        aria-label="Go Back"
        onClick={this.props.onClose || this.goBack}
      >
        <Icon
          name="chevron-left"
          size={20}
          style={{ height: 22, width: 22 }}
        />
        {/* <span style={{ fontSize: 14 }}>Back</span> */}
      </IconButton>
    );
  };

  render() {
    const { auth, eventId } = this.props;
    const { hasError, error, errorInfo } = this.state;
    const styles = {};
    const insetTop = safeAreaInsets.top;
    if (window.Capacitor.platform === 'ios') {
      styles.height = `${56 + insetTop}px`;
      styles.paddingTop = insetTop;
    }
    if (hasError) {
      return (
        <div style={{ textAlign: 'center', margin: 16, marginTop: 16 + insetTop }}>
          <AppBar
            // position="static"
            color="white"
            style={styles}
          >
            <Toolbar>
              { this.backArrow() }
            </Toolbar>
          </AppBar>
          <h1 style={{ marginBottom: 10, paddingTop: 70 }}>Something went wrong</h1>
          <p>
            {'The problem has been reported to our team and they are working on a fix. If the issue is a bug, most are fixed within 24 hours. Otherwise, issues are typically resolved within the hour. Visit '}
            <a href="http://status.boxpressd.com">our status page</a>
            {' for any active incident reports.'}
          </p>
          <br />
          {auth && auth.user && (
            <div>
              <p>Would you like to be notified as soon as an update is available?</p>
              <textarea
                placeholder="Let us know what happened (optional)"
                value={this.state.comment}
                onChange={(e) => this.setState({ comment: e.target.value })}
                className="form-control"
                style={{ width: '100%', maxWidth: 800, margin: 'auto' }}
              />
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: 20 }}
                onClick={() => {
                  this.setState({ submitting: true });
                  axios.post(`${config.apiEndPoint}/support/create`, {
                    event_id: eventId,
                    user_id: auth.user.id,
                    full_name: auth.user.full_name,
                    email: auth.user.email,
                    subject: `Crash report resolution requested by ${auth.user.full_name}`,
                    description: this.state.comment || 'Please let me know as soon as this issue is fixed.',
                    custom_fields: {
                      user: {
                        first_name: auth.user.first_name,
                        last_name: auth.user.last_name,
                      },
                      error_message: error ? error.toString() : 'Unknown error',
                      stack_trace: errorInfo.componentStack,
                      additional_data: [{
                        key: 'Project',
                        value: 'boxpressd-react',
                      }, {
                        key: 'Version',
                        value: packageJson.version,
                      }, {
                        key: 'Platform',
                        value: window.Capacitor.isNative ? window.Capacitor.platform : 'web',
                      }],
                      // TODO Include the body of last Axios request if available?
                    },
                  }).then(() => {
                    this.goBack(); // FIXME What if the error is on the home page? Refresh?
                  }).catch((err) => {
                    console.log(err);
                    Sentry.captureException(error);
                    this.goBack();
                  });
                }}
              >
                {this.state.submitting ? 'Submitting...' : 'Notify Me When Fixed'}
              </Button>
            </div>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
  };
}

export default connect(mapStateToProps)(ErrorBoundary);
