import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { isMobile } from 'mobile-device-detect';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Button as MaterialButton, InputBase, MenuItem, Select } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import AsyncCreatableSelect from 'react-select/async-creatable';
import ModalView from '../../components/ModalView';
import Icon from '../../components/Icon';
import './style.scss';
import { config } from '../../settings';
import MediaCropper from '../../components/cropper';
import * as Sentry from '@sentry/react';
import ErrorLogger from '../../utils/errorLogger';
import Hashids from 'hashids/cjs';
import LoadingIndicator from '../../components/LoadingIndicator';
import ModalDialog from '../../components/ModalDialog';
import BottomSheet from '../../components/bottom-sheet';
import HumidorEntryEditor from '../HumidorEntryEditor';
import SessionEditor from '../SessionEditor';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { SelectStyle } from '../../components/search-select';
import { addToast as actionAddToast } from '../../actions';
import { toBase64, uploadToS3 } from '../../utils/imageUtils';
import { v4 as uuidv4 } from 'uuid';
import Cigar from '../../models/Cigar';
import MobilePicker from '../../components/MobilePicker';
import SearchPicker from '../../components/SearchPicker';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Placeholder from '../../../config/placeholder.config';
import Badge from '@material-ui/core/Badge';
import { renderTimestamp } from '../../utils/formatting';

const hashids = new Hashids('', 12);

const countries = [
  'Cuba',
  'Dominican Republic',
  'Nicaragua',
  'Costa Rica',
  'Honduras',
];

const leafCountries = [
  'Cuban',
  'Dominican',
  'Nicaraguan',
  'Costa Rican',
  'Honduran',
  'Brazilian',
];

const commonWrappers = [
  'Cameroon',
  'Connecticut',
  'Connecticut Broadleaf',
  'Connecticut Shade',
  'Ecuador Habano',
  'Sumatra',
];

// TODO Make this its own component
const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      // marginTop: theme.spacing(3), // FIXME In the component, make this a style / setting - we don't always need this
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 12,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#d5c196',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

let submittedScan;
let forceSave = false;

export function CigarEditor(props) {
  const [cigar, setCigar] = useState({});
  const [helpMatch, setHelpMatch] = useState(null);
  const [customCigar, setCustomCigar] = useState(false);

  const [shouldPromptImage, setShouldPromptImage] = useState(true);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [missingBrand, setMissingBrand] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const [missingName, setMissingName] = useState(false);
  const [manufacturers, setManufacturers] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [origins, setOrigins] = useState(countries);
  const [selectedOrigin, setSelectedOrigin] = useState(null);
  const [wrappers, setWrappers] = useState(commonWrappers);
  const [selectedWrapper, setSelectedWrapper] = useState(null);
  const [binders, setBinders] = useState(leafCountries);
  const [selectedBinder, setSelectedBinder] = useState(null);
  const [fillers, setFillers] = useState(leafCountries);
  const [selectedFiller, setSelectedFiller] = useState(null);
  const [selectedStrength, setSelectedStrength] = useState(null);
  const [showActions, setShowActions] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState('');

  const [matchRequests, setMatchRequests] = useState([]);
  const [pendingCigars, setPendingCigars] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [promptedMedia, setPromptedMedia] = useState(false);
  const [showMediaCropper, setShowMediaCropper] = useState(false);
  const [uploadingMedia, setUploadingMedia] = useState(false);
  const [requireImage, setRequireImage] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  // const [submittedScan, setSubmittedScan] = useState(null);
  const [showHumidorEntryEditor, setShowHumidorEntryEditor] = useState(false);
  const [showSessionModal, setShowSessionModal] = useState(false);

  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [showMobilePicker, setShowMobilePicker] = useState(false);
  const [showSearchPicker, setShowSearchPicker] = useState(null);

  let addPhoto;

  const actions = [{
    content: 'Add to Humidor',
    value: 'add',
    onClick: () => setShowHumidorEntryEditor(true),
  }, {
    content: 'Smoke Now',
    value: 'smoke',
    onClick: () => setShowSessionModal(true),
    // FIXME Need to think through these actions some more - I really think if we want to include all, this needs to be
    //  its own view instead of just a bottom sheet / popup so the user can't attempt to submit the cigar again to "leave"
    //  or get the options to show up again
  // }, {
  //   content: 'Add to Favorites',
  //   value: 'favorite',
  //   onClick: () => {
  //     if (submittedScan.cigar) {
  //       axios.post(`${config.apiEndPoint}/users/${props.auth.user.id}/favorites`, {
  //         cigar,
  //         favorite: true,
  //       }).then((response) => {
  //         console.log(response);
  //         closeModal();
  //       }).catch((err) => {
  //         console.log(err);
  //       });
  //
  //       props.addToast({
  //         content: (<>Added to Favorites</>),
  //         duration: 6000,
  //       });
  //     } else if (submittedScan.id) {
  //       // TODO Somehow save with the scan ID?
  //       closeModal();
  //     } else {
  //       // FIXME Should be no else here - the option should have been thrown out, but this shouldn't happen
  //       closeModal();
  //     }
  //   },
  // }, {
  //   content: 'Add to Try List',
  //   value: 'trylist',
  //   onClick: () => {
  //     if (submittedScan.cigar) {
  //       saveItem(props.auth.user, {
  //         singular: 'try_list',
  //         plural: 'try_list',
  //       }, submittedScan.cigar);
  //     } else if (submittedScan.id) {
  //       // TODO Somehow save with the scan ID?
  //       closeModal();
  //     } else {
  //       // FIXME Should be no else here - the option should have been thrown out, but this shouldn't happen
  //       closeModal();
  //     }
  //   },
  }];

  const updateRecents = (user) => {
    axios.get(`${config.apiEndPoint}/users/${user.id}/matches/pending`, { params: { limit: 2 } }).then((result) => {
      console.log(result);
      setMatchRequests(result.data);
    }).catch((err) => {
      console.log(err);
    });

    axios.get(`${config.apiEndPoint}/users/${user.id}/cigars/pending`, { params: { limit: 2 } }).then((result) => {
      console.log(result);
      setPendingCigars(result.data);
    }).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    const { auth } = props;
    if (auth) {
      const { user } = auth;
      if (user) {
        updateRecents(user);
      }
    }
  }, [props.auth && props.auth.user]);

  useEffect(() => {
    console.log('Now editing cigar:');
    console.log(props.cigar);
    const { cigar } = props;
    setCigar(cigar);

    if (cigar.brand) {
      setSelectedBrand({
        label: cigar.brand,
        value: cigar.brand,
      });
    }
    if (cigar.name) {
      setSelectedName(cigar.name);
    }
    if (cigar.affiliate) {
      setSelectedManufacturer({ label: cigar.affiliate, value: cigar.affiliate });
    }
    if (cigar.origin) {
      setSelectedOrigin({
        label: cigar.origin,
        value: cigar.origin,
      });
    }
    if (cigar.wrapper) {
      setSelectedWrapper(cigar.wrapper.split(', ').map((w) => ({
        label: w,
        value: w,
      })));
    }
    if (cigar.binder) {
      setSelectedBinder(cigar.binder.split(', ').map((b) => ({
        label: b,
        value: b,
      })));
    }
    if (cigar.filler) {
      setSelectedFiller(cigar.filler.split(', ').map((f) => ({
        label: f,
        value: f,
      })));
    }
    if (!selectedStrength) {
      setSelectedStrength(cigar.strength || 1);
    }

    // return () => {
    //   // FIXME This isn't working - needs to reset so if we open this back up after going back, it prompts for an image again
    //   setPromptedMedia(false);
    // };
  }, [props.cigar]);

  useEffect(() => {
    // FIXME Would be nice to allow the image to upload behind the scenes but this gets reset when uploadingMedia is added to deps
    // if (helpMatch === null) {
    setHelpMatch(props.helpMatch || false);
    // }
  }, [props.helpMatch]);

  useEffect(() => {
    // FIXME This isn't fool-proof --- if this code runs multiple times before shouldSubmit is set to false, we will get duplicates submitted
    if (shouldSubmit) {
      setShouldSubmit(false);
      onSave();
    }
  }, [shouldSubmit]);

  const toggleOpen = () => {
    const { toggle } = props;
    console.log('Toggling inside modal...');
    if (toggle && typeof toggle === 'function') {
      toggle();
    }
  };

  const resetDefaults = () => {
    const { auth } = props;
    setSelectedImage(null);
    setSelectedImageUrl(null);
    setRequireImage(props.requireImage);
    setPromptedMedia(false);
    setShouldSubmit(false);
    setSelectedStrength(1);
    setHelpMatch(props.helpMatch);
    // FIXME These below are not being reset - UPDATE: I conditionally add the CigarEditor when it needs to be shown, and this is fixed now. Resetting is no longer necessary
    setSelectedBrand(null);
    setSelectedName('');
    setSelectedManufacturer(null);
    setSelectedOrigin(null);
    setSelectedWrapper(null);
    setSelectedBinder(null);
    setSelectedFiller(null);
    if (auth) {
      const { user } = auth;
      if (user) {
        // This usually happens when a user submits something, so update the view with the changes - this should help
        // if they need to continue some action
        updateRecents(user);
      }
    }
  };

  const closeModal = () => {
    const { onClose } = props;

    resetDefaults();
    setShouldPromptImage(true);

    console.log('Closing inside modal...');
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  const loadBrands = (inputValue, callback) => {
    axios.get(`${config.apiEndPoint}/cigars/brands?q=${inputValue}`).then((results) => {
      console.log(results);
      callback(results.data.map((brand) => ({
        value: brand, // FIXME Eventually, this should be an ID
        label: brand,
      })));
    });
  };

  const loadManufacturers = (inputValue, callback) => {
    axios.get(`${config.apiEndPoint}/cigars/manufacturers?q=${inputValue}`).then((results) => {
      console.log(results);
      callback(results.data.map((origin) => ({
        value: origin,
        label: origin,
      })));
    });
  };

  const loadOrigins = (inputValue, callback) => {
    axios.get(`${config.apiEndPoint}/cigars/origins?q=${inputValue}`).then((results) => {
      console.log(results);
      callback(results.data.map((origin) => ({
        value: origin,
        label: origin,
      })));
    });
  };

  const loadWrappers = (inputValue, callback) => {
    axios.get(`${config.apiEndPoint}/cigars/wrappers?q=${inputValue}`).then((results) => {
      console.log(results);
      callback(results.data.map((wrapper) => ({
        value: wrapper,
        label: wrapper,
      })));
    });
  };

  const loadBinders = (inputValue, callback) => {
    axios.get(`${config.apiEndPoint}/cigars/binders?q=${inputValue}`).then((results) => {
      console.log(results);
      callback(results.data.map((binder) => ({
        value: binder,
        label: binder,
      })));
    });
  };

  const loadFillers = (inputValue, callback) => {
    axios.get(`${config.apiEndPoint}/cigars/fillers?q=${inputValue}`).then((results) => {
      console.log(results);
      callback(results.data.map((filler) => ({
        value: filler,
        label: filler,
      })));
    });
  };

  const showRequireImageModal = () => {
    console.log('Showing image modal...');
    const buttons = customCigar ? [{
      label: 'Skip',
      onClick: () => {
        setRequireImage(false);
        setShouldPromptImage(false);
        setShouldSubmit(true);
        ModalDialog.close();
      },
    }, {
      label: 'Add Image',
      onClick: () => {
        setShouldPromptImage(true);
        ModalDialog.close();
      },
    }] : [{
      label: 'Add Image',
      onClick: () => {
        setShouldPromptImage(true);
        ModalDialog.close();
      },
    }];
    ModalDialog.show({
      title: 'An image is required',
      message: 'Please upload an image of the cigar before submitting',
      buttons,
    });
  };

  const helpMatchRequest = () => {
    console.log('Running help match code...');
    if (!selectedImageUrl && !uploadingMedia && requireImage) {
      console.log('Missing required field');
      showRequireImageModal();
      return;
    }
    setSubmitting(true);
    axios.post(`${config.apiEndPoint}/scans/match`, {
      user_id: props.auth.user.id,
      image_url: selectedImageUrl,
    }).then((result) => {
      console.log(result);
      resetDefaults();
      submittedScan = result.data;
      setSubmitting(false);
      setShowActions(true);
      setSubmissionMessage("Thank you! We'll let you know as soon as we match it. What would you like to do with this cigar?");
    }).catch((err) => {
      console.log(err);
      ErrorLogger.captureException(err);
      setSubmitting(false);
      ModalDialog.show({
        title: 'Unable to submit cigar details',
        message: 'An error occurred while submitting the cigar details. If the problem persists, please contact Help & Support from the main menu.',
        buttons: [{
          label: 'Dismiss',
          onClick: () => {
            ModalDialog.close();
          },
        }],
      });
    });
  };

  const onSave = () => {
    if (!uploadingMedia) {
      // FIXME They need to log in first
      if (helpMatch) {
        helpMatchRequest();
      } else {
        if (props.auth && props.auth.user && props.auth.user.id) {
          cigar.added_by = props.auth.user.id;
        }
        // TODO Might want to use Object.assign({}, cigar) here
        cigar.brand = selectedBrand ? selectedBrand.value : null;
        cigar.name = selectedName;
        cigar.affiliate = selectedManufacturer ? selectedManufacturer.value : null;
        cigar.origin = selectedOrigin ? selectedOrigin.value : null;
        if (selectedWrapper) {
          cigar.wrapper = joinArray(selectedWrapper);
        }
        if (selectedBinder) {
          cigar.binder = joinArray(selectedBinder);
        }
        if (selectedFiller) {
          cigar.filler = joinArray(selectedFiller);
        }
        cigar.strength = selectedStrength;
        cigar.custom = customCigar;
        // cigar.pending = pending || false;

        console.log('Saving cigar...');
        console.log(cigar);

        if (!cigar.brand) {
          if (!customCigar && !cigar.name) {
            helpMatchRequest();
            return;
          }
          if (customCigar && !cigar.name) {
            console.log('Missing required field');
            setMissingName(true);
            return;
          } else {
            setMissingName(false);
          }
          if (!customCigar) {
            console.log('Missing required field');
            setMissingBrand(true);
            setMissingName(false);
            return;
          }
        }
        setMissingBrand(false);

        if (cigar.id) {
          console.log('Reporting incorrect cigar details...');
          axios.put(`${config.apiEndPoint}/cigars/${cigar.id}/report`, cigar).then((result) => {
            console.log(result); // Should be { status: 'Success' }
            props.addToast({
              content: (<>Thank you for submitting the details!</>),
              duration: 6000,
            });
            closeModal();
          }).catch((err) => {
            console.log(err);
          });
        } else {
          console.log('Adding pending cigar...');
          if (!cigar.image_url) {
            if (!selectedImageUrl && !uploadingMedia && requireImage) {
              console.log('Missing required field');
              showRequireImageModal();
              return;
            }
            cigar.image_url = selectedImageUrl;
          }
          setSubmitting(true);
          console.log(cigar);
          axios.post(`${config.apiEndPoint}/cigars/pending?force=${forceSave}`, cigar).then((response) => {
            console.log(response.data);
            setSubmitting(false);
            forceSave = false;
            if (response.data.cigars) {
              setSuggestions(response.data.cigars);
              setShowSuggestions(true);
            } else {
              resetDefaults();

              submittedScan = {
                user: props.auth.user,
                cigar: response.data.cigar,
              };

              let message = 'Thank you for submitting this information! What would you like to do with this cigar?';
              if (customCigar) {
                message = 'What would you like to do with this cigar?';
              }
              setSubmissionMessage(message);
              setShowActions(true);
            }
          }).catch((err) => {
            setSubmitting(false);
            forceSave = false;
            console.log(err);
            ErrorLogger.captureException(err);
            ModalDialog.show({
              title: 'Unable to submit cigar details',
              message: 'An error occurred while submitting the cigar details. If the problem persists, please contact Help & Support from the main menu.',
              buttons: [{
                label: 'Dismiss',
                onClick: () => {
                  ModalDialog.close();
                },
              }],
            });
          });
        }
      }
    } else {
      setShouldSubmit(true);
    }
  };

  const uploadMedia = (blob, extension) => {
    const uuid = uuidv4();
    const userHash = hashids.encode(props.auth.user ? props.auth.user.id : 'unknownUser');
    const filename = `${userHash}-${performance.now()}.${extension}`;

    console.log('Uploading to media server...');
    setUploadingMedia(true);

    uploadToS3(blob, 'cigars', filename, uuid).then((res) => {
      console.log('Uploaded to media server!');
      console.log(res.data);
      Sentry.setExtra('uploadMedia_res_data', JSON.stringify(res.data));
      setUploadingMedia(false);
      setSelectedImageUrl(res.data.media_url);
    }).catch((err) => {
      setUploadingMedia(false);
      console.log(err);
      ErrorLogger.captureException(err);
    });
  };

  const renderMediaCropper = () => (
    <MediaCropper
      src={selectedImageFile}
      open={showMediaCropper}
      toggle={() => setShowMediaCropper(!showMediaCropper)}
      onClose={() => setShowMediaCropper(false)}
      onSave={(croppedCanvas) => {
        setShowMediaCropper(false);
        const base64 = croppedCanvas.toDataURL();
        // FIXME If we decide to allow multiple images at some point
        // const thumbnails = selectedImageThumbnails;
        // thumbnails.push(base64);
        // setSelectedImageThumbnails(thumbnails);
        setSelectedImage(base64);
        croppedCanvas.toBlob((blob) => {
          console.log('Canvas blob');
          console.log(blob);
          uploadMedia(blob, base64.split(';')[0].split('/')[1]);
        }/* , 'image/png' */);
      }}
    />
  );

  const joinArray = (array) => {
    if (array && array.length) {
      return array.map((item) => item.value).join(', ');
    }
    return null;
  };

  const renderActions = () => {
    if (isMobile) {
      return (
        <BottomSheet
          message={submissionMessage}
          items={actions}
          open={showActions}
          toggle={() => {
            if (showActions) {
              // We're closing the modal, reset the cigar
              setCigar({});
            }
            setShowActions(!showActions);
          }}
        />
      );
    }
    return (
      <Modal
        isOpen={showActions}
        toggle={() => setShowActions(!showActions)}
        // Reset the cigar
        onClosed={() => setCigar({})}
        style={{ maxWidth: 300 }}
        fade
      >
        <div className="modal-header">
          <Button className="close" color="" onClick={() => setShowActions(false)}>
            <Icon name="x" />
          </Button>
        </div>
        <ModalBody>
          <div>{submissionMessage}</div>
          <List>
            {actions.map((action) => (
              <ListItem button onClick={action.onClick}>
                <ListItemText primary={action.content} />
              </ListItem>
            ))}
          </List>
        </ModalBody>
      </Modal>
    );
  };

  const renderSuggestions = () => {
    const suggestedCigars = [...suggestions, { id: -1, full_name: 'No, none of these. Continue submitting my details.' }];
    if (isMobile) {
      return (
        <BottomSheet
          message="Were you searching for one of these cigars?"
          items={suggestedCigars.map((item) => ({
            content: item.full_name,
            value: item.id,
            onClick: () => {
              // FIXME Probably allow them to pick what they want to do with it from here unless we can determine from their attempted action as query param
              // setShowActions(true);
              if (item.id && item.id !== -1) {
                props.history.push(`/cigars/${hashids.encode(item.id)}`);
              } else {
                forceSave = true;
                setShouldSubmit(true);
              }
            },
          }))}
          open={showSuggestions}
          toggle={() => setShowSuggestions(!showSuggestions)}
        />
      );
    }
    return (
      <Modal
        isOpen={showSuggestions}
        toggle={() => setShowSuggestions(!showSuggestions)}
        style={{ maxWidth: 300 }}
        fade
      >
        <div className="modal-header">
          <Button className="close" color="" onClick={() => setShowSuggestions(false)}>
            <Icon name="x" />
          </Button>
        </div>
        <ModalBody>
          <div>Were you searching for one of these cigars?</div>
          <List>
            {suggestedCigars.map((suggestion) => (
              <ListItem
                button
                onClick={() => {
                  if (suggestion.id && suggestion.id !== -1) {
                    props.history.push(`/cigars/${hashids.encode(suggestion.id)}`);
                  } else {
                    forceSave = true;
                    setShouldSubmit(true);
                  }
                }}
              >
                {/* TODO Include avatar image if id !== -1 */}
                <ListItemText primary={suggestion.full_name} />
              </ListItem>
            ))}
          </List>
        </ModalBody>
      </Modal>
    );
  };

  const renderSessionModal = () => {
    console.log('Rendering session modal for scan:');
    console.log(submittedScan);
    return (
      <SessionEditor
        scan={submittedScan}
        open={showSessionModal}
        toggle={() => setShowSessionModal(!showSessionModal)}
        onClose={() => {
          setShowSessionModal(false);
          closeModal();
        }}
      />
    );
  };

  const renderHumidorEntryEditor = () => (
    <HumidorEntryEditor
      scan={submittedScan}
      open={showHumidorEntryEditor}
      toggle={() => setShowHumidorEntryEditor(!showHumidorEntryEditor)}
      onClose={() => {
        setShowHumidorEntryEditor(false);
        closeModal();
      }}
    />
  );

  const renderHelpMatchRow = () => (
    <ListItem>
      <ListItemText primary="Help me match this cigar" secondary="Not sure of any of the cigar details? Tap here to submit the cigar image you just took and we'll do the rest." />
      <ListItemSecondaryAction>
        <Switch
          edge="end"
          onChange={() => setHelpMatch(!helpMatch)}
          checked={helpMatch}
          inputProps={{ 'aria-labelledby': 'switch-list-label-dark-mode' }}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );

  const renderCustomCigarRow = () => (
    <ListItem>
      <ListItemText primary="This is a custom cigar" secondary="Rolled myself, rolled by a friend, rolled at a party, no band, etc" />
      <ListItemSecondaryAction>
        <Switch
          edge="end"
          onChange={() => setCustomCigar(!customCigar)}
          checked={customCigar}
          inputProps={{ 'aria-labelledby': 'switch-list-label-dark-mode' }}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );

  const renderMobilePicker = () => (
    <MobilePicker
      open={showMobilePicker}
      optionGroups={{
        // FIXME Would be nice if they default to Unknown, since the user can just submit Mild when that's not the case - granted, we look at it and fix it anyway..
        // strength: [...{ label: 'Unknown', value: null }, Cigar.strengths.map((strength, index) => ({ label: strength, value: index + 1 }))],
        strength: Cigar.strengths.map((strength, index) => ({ label: strength, value: index + 1 })),
      }}
      valueGroups={{
        strength: selectedStrength,
      }}
      toggle={() => setShowMobilePicker(!showMobilePicker)}
      handleChange={(group, value) => {
        if (group === 'strength') {
          // TODO May want to close the picker after selection
          setSelectedStrength(value);
        }
      }}
    />
  );

  const renderSearchPicker = () => {
    const type = showSearchPicker;
    let results;
    // FIXME Is there a better way to handle all of this without all these switches?
    switch (type) {
      case 'brand': {
        results = brands;
        break;
      }
      case 'origin': {
        results = origins;
        break;
      }
      case 'manufacturer': {
        results = manufacturers;
        break;
      }
      case 'wrapper': {
        results = wrappers;
        break;
      }
      case 'binder': {
        results = binders;
        break;
      }
      case 'filler': {
        results = fillers;
        break;
      }
      default:
        results = [];
        break;
    }
    return (
      <SearchPicker
        create
        multiple={type === 'wrapper' || type === 'binder' || type === 'filler'}
        open={showSearchPicker}
        onClose={() => setShowSearchPicker(null)}
        results={results}
        title={`Set ${type}`}
        searchPlaceholder={`Search ${type}s...`}
        onQueryChange={(query) => {
          switch (type) {
            case 'brand': {
              loadBrands(query, (items) => {
                setBrands(items.map((item) => item.label));
              });
              break;
            }
            case 'origin': {
              loadOrigins(query, (items) => {
                setOrigins(items.map((item) => item.label));
              });
              break;
            }
            case 'manufacturer': {
              loadManufacturers(query, (items) => {
                setManufacturers(items.map((item) => item.label));
              });
              break;
            }
            case 'wrapper': {
              loadWrappers(query, (items) => {
                setWrappers(items.map((item) => item.label));
              });
              break;
            }
            case 'binder': {
              loadBinders(query, (items) => {
                setBinders(items.map((item) => item.label));
              });
              break;
            }
            case 'filler': {
              loadFillers(query, (items) => {
                setFillers(items.map((item) => item.label));
              });
              break;
            }
            default:
              break;
          }
        }}
        onSelect={(result) => {
          console.log('Selected result:');
          console.log(result);
          switch (type) {
            case 'brand': {
              setSelectedBrand(result);
              break;
            }
            case 'origin': {
              setSelectedOrigin(result);
              break;
            }
            case 'manufacturer': {
              setSelectedManufacturer(result);
              break;
            }
            case 'wrapper': {
              setSelectedWrapper(result);
              break;
            }
            case 'binder': {
              setSelectedBinder(result);
              break;
            }
            case 'filler': {
              setSelectedFiller(result);
              break;
            }
            default:
              break;
          }
          setShowSearchPicker(null);
        }}
      />
    );
  };

  // FIXME The only issue with these is that we automatically prompt for an image - maybe if these exist, prompt them to
  //  "pick" or "create new" but this should work for now
  const renderPendingMatchRequests = () => (
    <div>
      {matchRequests.length > 0 && <h4 style={{ marginBottom: 0, margin: 16 }}>Recent Open Cigar Match Requests</h4>}
      <List>
        {matchRequests.slice(0, 5).map((request) => (
          <ListItem
            onClick={() => {
              submittedScan = request.scan;
              setShowActions(true);
            }}
          >
            <ListItemAvatar>
              <Badge color="error" overlap="circular" badgeContent=" " variant="dot">
                <Avatar src={request.image_url}><img src={Placeholder.cigar} style={{ height: 40, width: 40 }} /></Avatar>
              </Badge>
            </ListItemAvatar>
            <ListItemText
              primary={<span>{`Requested ${renderTimestamp(request.timestamp)}`}</span>}
              secondary={'Tap to show actions'}
            />
            <ListItemSecondaryAction
              onClick={() => {
                submittedScan = request.scan;
                setShowActions(true);
              }}
            >
              <Icon name="chevron-right" style={{ height: 18, width: 18, marginLeft: 10 }} />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const renderPendingCigars = () => (
    <div>
      {pendingCigars.length > 0 && <h4 style={{ marginBottom: 0, margin: 16 }}>Recently Submitted Cigars Pending Admin Approval</h4>}
      <List>
        {pendingCigars.slice(0, 5).map((scan) => (
          <ListItem
            onClick={() => {
              submittedScan = scan;
              setShowActions(true);
            }}
          >
            <ListItemAvatar>
              <Badge color="error" overlap="circular" badgeContent=" " variant="dot">
                <Avatar src={scan.image_url || scan.cigar.image_url}><img src={Placeholder.cigar} style={{ height: 40, width: 40 }} /></Avatar>
              </Badge>
            </ListItemAvatar>
            <ListItemText
              primary={<span>{scan.cigar.full_name}</span>}
              secondary={'Tap to show actions'}
            />
            <ListItemSecondaryAction
              onClick={() => {
                submittedScan = scan;
                setShowActions(true);
              }}
            >
              <Icon name="chevron-right" style={{ height: 18, width: 18, marginLeft: 10 }} />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const renderMobileForm = () => (
    <div>
      { selectedImage && (
        <div style={{ textAlign: 'center' }}>
          <img src={selectedImage} style={{ height: 100, width: 100, objectFit: 'contain' }} />
        </div>
      )}
      <List>
        { !cigar.id && !customCigar ? renderHelpMatchRow() : null }
        {!helpMatch && (
          <>
            { !cigar.id ? renderCustomCigarRow() : null }
            {!customCigar && (
              <ListItem onClick={() => setShowSearchPicker('brand')}>
                <ListItemText primary={<span style={missingBrand ? { color: '#ef5164' } : null}>Brand</span>} />
                <ListItemSecondaryAction onClick={() => setShowSearchPicker('brand')}>
                  {(selectedBrand && selectedBrand.label) || ''}
                  <Icon name="chevron-right" style={{ height: 18, width: 18, marginLeft: 10 }} />
                </ListItemSecondaryAction>
              </ListItem>
            )}
            <ListItem>
              <input
                className={`form-control no-background${missingName ? ' error-placeholder' : ''}`}
                placeholder={!customCigar ? 'Blend Name' : 'Name, Event, or Roller'}
                value={selectedName}
                onChange={(e) => setSelectedName(e.target.value)}
              />
            </ListItem>
            <ListItem onClick={() => setShowSearchPicker('manufacturer')}>
              <ListItemText primary="Manufacturer" />
              <ListItemSecondaryAction onClick={() => {}}>
                {(selectedManufacturer && selectedManufacturer.label) || ''}
                <Icon name="chevron-right" style={{ height: 18, width: 18, marginLeft: 10 }} />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem onClick={() => setShowSearchPicker('origin')}>
              <ListItemText primary="Country of Origin" />
              <ListItemSecondaryAction onClick={() => setShowSearchPicker('origin')}>
                {(selectedOrigin && selectedOrigin.label) || ''}
                <Icon name="chevron-right" style={{ height: 18, width: 18, marginLeft: 10 }} />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem onClick={() => setShowSearchPicker('wrapper')}>
              <ListItemText primary="Wrapper" secondary={joinArray(selectedWrapper) && joinArray(selectedWrapper).length > 30 ? joinArray(selectedWrapper) : ''} />
              <ListItemSecondaryAction onClick={() => setShowSearchPicker('wrapper')}>
                {joinArray(selectedWrapper) && joinArray(selectedWrapper).length <= 30 ? joinArray(selectedWrapper) : ''}
                <Icon name="chevron-right" style={{ height: 18, width: 18, marginLeft: 10 }} />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem onClick={() => setShowSearchPicker('binder')}>
              <ListItemText primary="Binder" secondary={joinArray(selectedBinder) && joinArray(selectedBinder).length > 30 ? joinArray(selectedBinder) : ''} />
              <ListItemSecondaryAction onClick={() => setShowSearchPicker('binder')}>
                {joinArray(selectedBinder) && joinArray(selectedBinder).length <= 30 ? joinArray(selectedBinder) : ''}
                <Icon name="chevron-right" style={{ height: 18, width: 18, marginLeft: 10 }} />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem onClick={() => setShowSearchPicker('filler')}>
              <ListItemText primary="Filler" secondary={joinArray(selectedFiller) && joinArray(selectedFiller).length > 30 ? joinArray(selectedFiller) : ''} />
              <ListItemSecondaryAction onClick={() => setShowSearchPicker('filler')}>
                {joinArray(selectedFiller) && joinArray(selectedFiller).length <= 30 ? joinArray(selectedFiller) : ''}
                <Icon name="chevron-right" style={{ height: 18, width: 18, marginLeft: 10 }} />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem onClick={() => setShowMobilePicker(true)}>
              <ListItemText primary="Strength" />
              <ListItemSecondaryAction onClick={() => setShowMobilePicker(true)}>
                {Cigar.formattedStrength(selectedStrength - 1)}
                <Icon name="chevron-right" style={{ height: 18, width: 18, marginLeft: 10 }} />
              </ListItemSecondaryAction>
            </ListItem>
          </>
        )}
      </List>
    </div>
  );

  const renderDesktopForm = () => (
    <List>
      { selectedImage && (
        <div style={{ textAlign: 'center' }}>
          <img src={selectedImage} style={{ height: 100, width: 100, objectFit: 'contain' }} />
        </div>
      )}
      { !cigar.id && !customCigar ? renderHelpMatchRow() : null }
      {!helpMatch && (
        <>
          { !cigar.id ? renderCustomCigarRow() : null }
          {!customCigar && (
            <ListItem>
              <FormGroup style={{ width: '100%', marginBottom: 0 }}>
                <Label style={{ color: missingBrand ? '#ef5164' : 'inherit' }}>
                  Brand
                </Label>
                {/* FIXME All of these require the timeout in onChange for it to override the blur - see https://github.com/JedWatson/react-select/issues/3507 in case they fix it */}
                <AsyncCreatableSelect
                  styles={SelectStyle}
                  placeholder="Type to search..."
                  className={missingBrand ? 'select-error form-select' : 'form-select'}
                  value={selectedBrand}
                  onBlur={(e) => {
                    const name = e.target.value;
                    if (name) {
                      setSelectedBrand({
                        value: name,
                        label: name.toLowerCase()
                          .split(' ')
                          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                          .join(' '),
                      });
                    }
                  }}
                  onBlurResetsInput={false}
                  onChange={(value) => {
                    setSelectedBrand(value);
                  }}
                  getOptionLabel={(option) => `${option.label}`}
                  getOptionValue={(option) => `${option}`}
                  isOptionSelected={(option) => {
                    if (selectedBrand) {
                      return selectedBrand.value === option.value;
                    }
                    return false;
                  }}
                  loadOptions={loadBrands}
                  defaultOptions={brands.map((value) => ({ value, label: value }))}
                />
              </FormGroup>
            </ListItem>
          )}
          <ListItem>
            <FormGroup style={{ width: '100%', marginBottom: 0 }}>
              <Label style={{ color: missingName ? '#ef5164' : 'inherit' }}>
                {customCigar ? 'Name, Event, or Roller' : 'Blend Name'}
              </Label>
              <Input
                type="text"
                name="cigar-name"
                id="cigar-name"
                className={missingName ? 'select-error form-input' : 'form-input'}
                placeholder="Ex.: Handrolled at Miami Beach"
                onChange={(e) => setSelectedName(e.target.value)}
                value={selectedName}
              />
            </FormGroup>
          </ListItem>
          <ListItem>
            <FormGroup style={{ width: '100%', marginBottom: 0 }}>
              <Label for="city">
                Manufacturer
              </Label>
              <AsyncCreatableSelect
                styles={SelectStyle}
                placeholder="Type to search..."
                className="form-select"
                value={selectedManufacturer}
                onBlur={(e) => {
                  setSelectedManufacturer({
                    value: e.target.value,
                    label: e.target.value.toLowerCase()
                      .split(' ')
                      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      .join(' '),
                  });
                }}
                onChange={(value) => {
                  setSelectedManufacturer(value);
                }}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option}`}
                isOptionSelected={(option) => {
                  if (selectedManufacturer) {
                    return selectedManufacturer.value === option.value;
                  }
                  return false;
                }}
                loadOptions={loadManufacturers}
                defaultOptions={manufacturers.map((value) => ({ value, label: value }))}
              />
            </FormGroup>
          </ListItem>
          <ListItem>
            <FormGroup style={{ width: '100%', marginBottom: 0 }}>
              <Label for="city">
                Country of Origin
              </Label>
              <AsyncCreatableSelect
                styles={SelectStyle}
                placeholder="Type to search..."
                className="form-select"
                value={selectedOrigin}
                onBlur={(e) => {
                  e.persist();
                  console.log(e);
                  const name = e.target.value;
                  if (name) {
                    setSelectedOrigin({
                      value: name,
                      label: name.toLowerCase()
                        .split(' ')
                        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                        .join(' '),
                    });
                  }
                }}
                onBlurResetsInput={false}
                onChange={(value) => {
                  console.log(value);
                  setSelectedOrigin(value);
                }}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option}`}
                isOptionSelected={(option) => {
                  if (selectedOrigin) {
                    return selectedOrigin.value === option.value;
                  }
                  return false;
                }}
                loadOptions={loadOrigins}
                defaultOptions={origins.map((value) => ({ value, label: value }))}
              />
            </FormGroup>
          </ListItem>
          <ListItem>
            <FormGroup style={{ width: '100%', marginBottom: 0 }}>
              <Label for="city">
                Wrapper
              </Label>
              <AsyncCreatableSelect
                styles={SelectStyle}
                placeholder="Type to search..."
                className="form-select"
                value={selectedWrapper}
                onBlur={(e) => {
                  const name = e.target.value;
                  if (name) {
                    const newValue = {
                      value: name,
                      label: name.toLowerCase()
                        .split(' ')
                        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                        .join(' '),
                    };
                    if (Array.isArray(selectedWrapper)) {
                      // Don't allow duplicate values
                      if (selectedWrapper.find((v) => v.value === name)) { return; }
                      setSelectedWrapper([...selectedWrapper, newValue]);
                    } else {
                      setSelectedWrapper([newValue]);
                    }
                  }
                }}
                onChange={(value) => {
                  setSelectedWrapper(value);
                }}
                isMulti
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option}`}
                isOptionSelected={(option) => {
                  if (selectedWrapper) {
                    return selectedWrapper.value === option.value;
                  }
                  return false;
                }}
                loadOptions={loadWrappers}
                defaultOptions={wrappers.map((value) => ({ value, label: value }))}
              />
            </FormGroup>
          </ListItem>
          <ListItem>
            <FormGroup style={{ width: '100%', marginBottom: 0 }}>
              <Label for="city">
                Binder
              </Label>
              <AsyncCreatableSelect
                styles={SelectStyle}
                placeholder="Type to search..."
                className="form-select"
                value={selectedBinder}
                onBlur={(e) => {
                  // FIXME Any way to make this reusable?
                  const name = e.target.value;
                  if (name) {
                    const newValue = {
                      value: name,
                      label: name.toLowerCase()
                        .split(' ')
                        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                        .join(' '),
                    };
                    if (Array.isArray(selectedBinder)) {
                      // Don't allow duplicate values
                      if (selectedBinder.find((v) => v.value === name)) { return; }
                      setSelectedBinder([...selectedBinder, newValue]);
                    } else {
                      setSelectedBinder([newValue]);
                    }
                  }
                }}
                onChange={(value) => {
                  setSelectedBinder(value);
                }}
                isMulti
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option}`}
                isOptionSelected={(option) => {
                  if (selectedBinder) {
                    return selectedBinder.value === option.value;
                  }
                  return false;
                }}
                loadOptions={loadBinders}
                defaultOptions={binders.map((value) => ({ value, label: value }))}
              />
            </FormGroup>
          </ListItem>
          <ListItem>
            <FormGroup style={{ width: '100%', marginBottom: 0 }}>
              <Label for="city">
                Filler
              </Label>
              <AsyncCreatableSelect
                styles={SelectStyle}
                placeholder="Type to search..."
                className="form-select"
                value={selectedFiller}
                onBlur={(e) => {
                  const name = e.target.value;
                  if (name) {
                    const newValue = {
                      value: name,
                      label: name.toLowerCase()
                        .split(' ')
                        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                        .join(' '),
                    };
                    if (Array.isArray(selectedFiller)) {
                      // Don't allow duplicate values
                      if (selectedFiller.find((v) => v.value === name)) { return; }
                      setSelectedFiller([...selectedFiller, newValue]);
                    } else {
                      setSelectedFiller([newValue]);
                    }
                  }
                }}
                onChange={(value) => {
                  // FIXME These don't remove when adding this check but remove ALL without it
                  // if (value) {
                  setSelectedFiller(value);
                  // }
                }}
                isMulti
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option}`}
                isOptionSelected={(option) => {
                  if (selectedFiller) {
                    return selectedFiller.value === option.value;
                  }
                  return false;
                }}
                loadOptions={loadFillers}
                defaultOptions={fillers.map((value) => ({ value, label: value }))}
              />
            </FormGroup>
          </ListItem>
          <ListItem>
            <FormGroup style={{ width: '100%', marginBottom: 0 }}>
              <Label for="state">
                Strength
              </Label>
              <Select
                style={{ width: '100%' }}
                value={selectedStrength}
                selectedMenuItemStyle={{ color: '#d5c196' }}
                onChange={(event) => {
                  setSelectedStrength(event.target.value);
                }}
                input={<BootstrapInput />}
              >
                <MenuItem value={1}>Mellow</MenuItem>
                <MenuItem value={2}>Mellow-Medium</MenuItem>
                <MenuItem value={3}>Medium</MenuItem>
                <MenuItem value={4}>Medium-Full</MenuItem>
                <MenuItem value={5}>Full</MenuItem>
              </Select>
            </FormGroup>
          </ListItem>
        </>
      )}
    </List>
  );

  const renderPhotoUploadPicker = () => {
    setTimeout(() => {
      if (props.open && addPhoto && props.requireImage && shouldPromptImage) {
        addPhoto.click();
        setShouldPromptImage(false);
      }
    }, 100);
    return (
      <input
        id="photo-picker"
        type="file"
        accept="image/*"
        ref={(ref) => addPhoto = ref}
        style={{ display: 'none' }}
        onChange={(e) => {
          console.log('Picked file:');
          console.log(e.target.files);
          toBase64(e.target.files[0]).then((imageFile) => {
            setPromptedMedia(true);
            setSelectedImageFile(imageFile);
            setShowMediaCropper(true);
          });
        }}
      />
    );
  };

  const { open } = props;
  if (isMobile) {
    return (
      <ModalView
        open={open}
        showFrom="right"
        onClick={toggleOpen}
        onClose={closeModal}
        // path={'/sessions/edit'}
        // parentPath={'/cigars'}
        rightButtons={[
          <MaterialButton aria-label="Submit Cigar" onClick={onSave}>Submit</MaterialButton>,
        ]}
      >
        <div>
          { !cigar.id && renderPendingMatchRequests() }
          { !cigar.id && renderPendingCigars() }
          { renderMobileForm() }
          { renderActions() }
          { renderSuggestions() }
          { !promptedMedia && renderPhotoUploadPicker() }
          { renderMediaCropper() }
          { submitting && <LoadingIndicator overlay /> }
          { showHumidorEntryEditor && renderHumidorEntryEditor() }
          { showSessionModal && renderSessionModal() }
          { renderMobilePicker() }
          { showSearchPicker && renderSearchPicker() }
        </div>
      </ModalView>
    );
  }
  return (
    <Modal
      isOpen={open}
      toggle={toggleOpen}
      style={{ maxWidth: 600 }}
      fade
    >
      <div className="modal-header">
        <Button className="close" color="" onClick={closeModal}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        { open && !cigar.id && renderPendingMatchRequests() }
        { open && !cigar.id && renderPendingCigars() }
        { open && renderDesktopForm() }
        { renderActions() }
        { renderSuggestions() }
        { open && !promptedMedia && renderPhotoUploadPicker() }
        { open && renderMediaCropper() }
        { showHumidorEntryEditor && renderHumidorEntryEditor() }
        { showSessionModal && renderSessionModal() }
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={closeModal}>Close</Button>
        <Button color="primary" onClick={onSave}>Save</Button>
      </ModalFooter>
    </Modal>
  );
}

CigarEditor.propTypes = {
  cigar: PropTypes.object,
  open: PropTypes.bool,
  toggle: PropTypes.func,
  onClose: PropTypes.func,
  requireImage: PropTypes.func,
  helpMatch: PropTypes.bool,
};

CigarEditor.defaultProps = {
  cigar: {},
  open: false,
  toggle: () => {},
  onClose: () => {},
  requireImage: true,
  helpMatch: false,
};

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
  };
}

const mapDispatchToProps = (dispatch) => ({
  addToast: (data) => dispatch(actionAddToast(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CigarEditor));
